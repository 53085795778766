<template>
  <div class="content_mesage">
    <div class="content_crumbs">
      <el-breadcrumb separator-icon="ArrowRight">
        <el-breadcrumb-item :to="{ path: '/' }">当前位置：集团要闻</el-breadcrumb-item>
        <el-breadcrumb-item>正文</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content_tip">
      <div class="content_tip_title" v-if="showTable==1">
        <div class="modular_box" v-for="i in Table" :key="i.id">
          <div class="name">{{i.categoryName}}</div>
          <div class="box">
            <div style="height:10rem">
              <img :src="i && i.categoryImgUrl? i.categoryImgUrl: ''" alt="" style="width: 100%;height:100%;" class="image">
            </div>
            <div>
              <div class="box_title" style="text-align: left;">{{i.categoryName}}</div>
              <div class="bottom">
                <el-button type="warning" size="small" class="button" @click.stop="moreHtml(i)">更多 ></el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content_tip_title" v-for="item in Table" :key="item.id" @click="setHtml(item)" v-else>
        <div class="content_tip_mesage">
          <div class="mesage_title">{{item.description}}</div>
          <div class="mesage_content" v-html="item.content"></div>
          <div class="mesage_time">{{item.createTime.slice(0,11)}}</div>
        </div>
        <div class="content_tip_pic">
          <img :src="item && item.thumbnailUrlList && item.thumbnailUrlList[0] ? item.thumbnailUrlList[0].link : ''" alt="" class="img_pic">
        </div>
      </div>
      <div style="display:flex;justify-content: flex-end;padding-bottom:20px;">
        <el-pagination background v-model:currentPage="currentPage" v-model:page-size="pageSize" :page-sizes="[10, 20, 40]" layout="total, sizes, prev, pager, next, jumper" :total="pagetotal" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
    </div>
  </div>
</template>

<script>
import imgData from "@/data.js";
import { reactive, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { getNewsTable } from "@/api/index";
import { getAllChildrenNavWithParentId } from "@/api/newApi/newApi.js";
export default {
  setup() {
    const router = useRouter();
    const currentPage = ref(1)
    const pageSize = ref(10)
    let pagetotal = ref(1)
    const showTable = ref(1)
    const Table = ref()
    const handleSizeChange = (val) => {
      console.log(`${val} items per page`)
      pageSize.value = val
      pageTableT()
    }
    const handleCurrentChange = (val) => {
      console.log(`current page: ${val}`)
      currentPage.value = val
      pageTableT()
    }

    const idValue = ref('')
    const navGroup = ref('')
    // 子栏目请求接口
    const pageTableT = async () => {
      const contentNav = {}
      const query = {}
      contentNav.status = 1
      contentNav.id = idValue.value
      contentNav.navGroup = navGroup.value ? navGroup.value : ''
      query.current = currentPage.value || 1
      query.size = pageSize.value || 10
      const res = await getAllChildrenNavWithParentId(contentNav, query)
      if (res.data.records && res.data.records.length > 0) {
        pagetotal.value = res.data.total
        Table.value = res.data.records
        showTable.value = 1
      } else {
        await pageTable();
        showTable.value = 2
      }
    }
    // 资讯查询接口
    const pageTable = async () => {
      const res = await getNewsTable({
        current: currentPage.value || 1,
        size: pageSize.value || 10,
        id: idValue.value,
      });
      pagetotal.value = res.data.total
      Table.value = res.data.records
    };
    const setHtml = (item) => {
      router.push({ path: "/InfoView", query: { id: item.id } })
    };
    const moreHtml = async (item) => {
      console.log(item, 'item');
      idValue.value = item.id
      navGroup.value = item.navGroup
      router.push({ path: "/LIST", query: { Id: item.id, navGroup: item.navGroup } })
      await pageTableT()
    };
    onMounted(async () => {
      idValue.value = router.currentRoute.value.query.Id;
      navGroup.value = router.currentRoute.value.query.navGroup;
      await pageTableT()

    })
    const data = reactive({
      handleSizeChange,
      handleCurrentChange,
      pageTable,
      pageTableT
    });
    return {
      ...data,
      ...imgData,
      Table,
      pagetotal,
      activePage: "/index",
      haveToken: false,
      currentPage,
      pageSize,
      setHtml,
      moreHtml,
      showTable,
    };
  },
  created() {
    let href = window.location.href;
    let origin = window.location.origin + "/";
    this.activePage =
      href.replace(origin, "") == "BiddingInfo"
        ? ""
        : "/" + href.replace(origin, "");

    let arr = document.cookie.split(";");
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].indexOf("saber-access-token") !== -1) {
        this.haveToken = true;
      }
    }
  },
  methods: {

  },
};
</script>

<style scoped>
@import url("./conMes.css");
.modular_box {
  width: 23.9%;
  padding: 10px 0px;
  cursor: pointer;
}
.name {
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 10px;
  text-align: center;
}
.box {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  position: relative;
  min-height: 255px;
}
.box_title {
  width: 100%;
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  padding: 20px 10px 0 10px;
}
.bottom {
  margin-top: 13px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.button {
  width: 56px;
  height: 30px;
  margin: 10px 20px 10px 0;
  position: absolute;
  bottom: 0;
}
</style>