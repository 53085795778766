<template>
  <div class="header_li_wrap_c">
    <div class="header_li_right_c">
      <div v-for="item in listData" :key="item.code" :class="[Id == item.id ? 'active' : '']" class="header_li_right_item" :style="`cursor:pointer`" @click="pushRouter(item)" id="active">
        <p>{{item.categoryName}}
        </p>
        <img src="./picture/active.png" :style="Id == item.id ? '' : 'visibility: hidden'" />
      </div>
    </div>
  </div>
</template>

<script>
import imgData from "@/data.js";
import { reactive, computed, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { mainStore } from "@/store";
import { storeToRefs } from "pinia";
export default {
  props: {
    webTitle: String,
  },
  setup() {
    const store = mainStore();
    const webData = computed(() => storeToRefs(store).webData)
    const activePageUrl = computed(() => {
      return storeToRefs(store).activePageUrl;
    });

    const listData = computed(() => storeToRefs(store).navBar);

    const router = useRouter();
    const goToIndex = () => {
      store.setactivePageUrl("/index");
      router.push({ path: "/index" });
    };
    const Id = ref()
    const pushRouter = (item) => {
      const { categoryType, link, id, navGroup } = item;
      Id.value = id
      sessionStorage.setItem('Ids', id)
      const activeElements = document.querySelectorAll("#active")
      activeElements.forEach((el) => el.classList.remove('.active'));
      if (categoryType == 1 || categoryType == 2) {
        const path = link == "/" ? "/index" : link;
        router.push({ path: path, query: { Id: id, navGroup: navGroup } }).then(() => {
          location.reload();
        })
        store.setactivePageUrl(path);
      } else {
        window.open(link, "_blank");
      }
    };
    onMounted(() => {
      Id.value = sessionStorage.getItem('Ids')
    })
    const data = reactive({
      listData,
      activePageUrl,
      webData
    });
    return {
      ...data,
      ...imgData,
      Id,
      pushRouter,
      activePage: "/index",
      haveToken: false,
      goToIndex,
    };
  },
  created() {
    let href = window.location.href;
    let origin = window.location.origin + "/";
    this.activePage =
      href.replace(origin, "") == "BiddingInfo"
        ? ""
        : "/" + href.replace(origin, "");

    let arr = document.cookie.split(";");
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].indexOf("saber-access-token") !== -1) {
        this.haveToken = true;
      }
    }
  },
  methods: {
    goToLogin() {
      // console.log(process.env);
      window.open(process.env.VUE_APP_SEVERCE_PLATFORM, "_blank");
      // location.href = process.env.VUE_APP_SEVERCE_PLATFORM;
    },
    goToRegister() {
      window.open(process.env.VUE_APP_SEVERCE_REGISTER, "_blank");
      // location.href = process.env.VUE_APP_SEVERCE_REGISTER;
    },
  },
};
</script>

<style scoped>
@import url("./Header.css");
</style>