<template>
  <SecondaryLists></SecondaryLists>
</template>
<script>
import SecondaryLists from "@/components/compontents/newPage.vue";
export default {
  components: { SecondaryLists },
  setup() {
    return {};
  },
  methods: {
  },
};
</script>
<style>
</style>