import request from "@/core/request"
const getAllChildrenNavWithParentId = (contentNav, query) => {
  let url = '/api/fontController/fontController/getAllChildrenNavWithParentId';

  if (contentNav) {
    const contentNavParams = Object.entries(contentNav).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    const contentNavQueryString = contentNavParams.join('&');
    url += `?${contentNavQueryString}`;
  }

  if (query) {
    const queryParams = Object.entries(query).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    const queryQueryString = queryParams.join('&');
    url += `${contentNav ? '&' : '?'}${queryQueryString}`;
  }

  return request.request({
    url: url,
    method: 'GET',
  });
}

const getContentById = (params) => {
  return request.request({
    url: '/api/fontController/fontController/getContentById',
    method: 'GET',
    params
  })
}

export {
  getAllChildrenNavWithParentId,
  getContentById
}
