<template>
  <div class="head_box">
    <imgHead />
    <Header />
    <contentMesage />
    <NavFooter />
  </div>
</template>

<script>
import imgHead from "./img/imgHead.vue";
import Header from "./Header/Header.vue";
import NavFooter from "./Footer/Footer.vue";
import contentMesage from "./contentMesage/conMes.vue";
export default {
  components: { Header, NavFooter, imgHead, contentMesage },
  setup() {
    return {};
  },
  methods: {
  },
};
</script>